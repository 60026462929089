import React from 'react';
import SEOPageService from '../../services/contentful/SEOPageService';
import RedirectionService from '../../services/api/RedirectionService';
import menuAndFooterService from '../../services/contentful/menuAndFooterService';
import SeoPageTemplate from '../../components/Organisms/SEOPageTemplate';

const Level1Page = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SeoPageTemplate {...props} />
);

export default Level1Page;

export async function getServerSideProps({ params }) {
  let pageContent;
  let menuAndFooter;

  try {
    menuAndFooter = await menuAndFooterService.menuAndFooter();
  } catch (err) {
    menuAndFooter = {};
    console.error(err);
  }
  try {
    pageContent = await SEOPageService.pageContent(params?.level1);
    // If page content is null, ask api to know where to redirect
    if (!pageContent || !pageContent?.items?.[0]?.fields) {
      const redirection = await RedirectionService.getRedirection(params?.level1);
      return {
        redirect: {
          // destination: `/${redirection?.data?.destination_slug || '404'}`,
          destination: '/' + (redirection?.data?.destination_slug || '404'),
          permanent: false,
        },
      };
    }
  } catch (err) {
    pageContent = null;
    console.error(err);
    return {
      redirect: {
        destination: '/404',
        permanent: false,
      },
    };
  }

  return {
    props: {
      pageContent: pageContent?.items?.[0]?.fields || null,
      ...menuAndFooter?.fields,
    },
  };
}
